import { Control } from "./Control";
export class RadioControl extends Control {
    isOtherSelected: boolean | undefined;
    options: string[];
    // tslint:disable-next-line:no-any
    constructor(label: string, placeholder: string, options: string[], isValid: (val: any) => string) {
        super(label, placeholder, isValid);
        this.options = options;
    }
}
