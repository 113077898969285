import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Container, Row, Col, Alert, Form, FormGroup, Label, Input, Button, FormFeedback } from 'reactstrap';
import LoadingIndicator from './LoadingIndicator';
import BrevetSvc, { BrevetInfo } from './BrevetSvc';
import { Link } from 'react-router-dom';
import { Subscription } from 'rxjs/Subscription';
import { SetMenuFunc } from './WithMenu';
import { DropDownControl } from './DropDownControl';
import { RadioControl } from './RadioControl';
import { Control } from './Control';

const BikeTypes = process.env.REACT_APP_REGISTRATIONBIKETYPES ?
    JSON.parse(process.env.REACT_APP_REGISTRATIONBIKETYPES) as string[]
    : ['Шоссейник', 'Байк', 'Гибрид', 'Иное'];
const Clubs = process.env.REACT_APP_REGISTRATIONCLUBS ?
    JSON.parse(process.env.REACT_APP_REGISTRATIONCLUBS) as string[] :
    ['КБП', 'СТК "Велокурск"', 'Индивидуально'];
const Cities = process.env.REACT_APP_REGISTRATIONCITIES ?
    JSON.parse(process.env.REACT_APP_REGISTRATIONCITIES) as string[] :
    ['Курск', 'Суджа', 'Воронеж', 'Липецк', 'Белгород'];

export type inputType = 'text' | 'number' | 'tel';

interface Controls {
    nick: Control;
    lastName: Control;
    lastNameLat: Control;
    firstName: Control;
    firstNameLat: Control;
    yearOfBirth: Control;
    cellPhone: Control;
    relativesPhone: Control;
    city: RadioControl;
    club: RadioControl;
    bikeType: Control;
    [name: string]: Control | undefined;
};

class Register extends React.Component<RouteComponentProps<{ id?: string }> & { setMenu: SetMenuFunc },
    {
        isLoading: boolean,
        isSaving: boolean,
        // tslint:disable-next-line:no-any
        error?: any,
        errorSend?: string,
        brevet?: BrevetInfo,
        orderMedal: boolean,
        agreeWithBrevet: { value: boolean, touched: boolean },
        controls: Controls,
        registredNumber?: string
    }> {

    _isMounted: boolean | undefined;
    loader: Subscription | undefined;

    // tslint:disable-next-line:no-any
    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: true,
            isSaving: false,
            orderMedal: false,
            agreeWithBrevet: { value: false, touched: false },
            controls: {
                nick: new Control(
                    'Ник',
                    'Для отображения',
                    (val) => val != null && val.length > 0 ? '' :
                        'Ник необходим. Он появится в списке зарегистрированных'),
                lastName: new Control(
                    'Фамилия',
                    'Фамилия по-русски',
                    (val) => val == null || val.length === 0 ? 'Фамилия необходима каждому человеку'
                        : (/^[А-Яа-яёЁ-]*$/.test(val) ? '' : 'По-русски, пожалуйста')),
                lastNameLat: new Control(
                    'Фамилия в транскрипции',
                    'Фамилия латинскими буквами',
                    (val) => val == null || val.length === 0 || /^[A-Za-z-]*$/.test(val) ? '' :
                        'Латинскими буквами, пожалуйста'),
                firstName: new Control(
                    'Имя',
                    'Имя по-русски',
                    (val) => val == null || val.length === 0 ? 'Имя тоже необходимо человеку'
                        : (/^[А-Яа-яёЁ-]*$/.test(val) ? '' : 'По-русски, пожалуйста')),
                firstNameLat: new Control(
                    'Имя в транскрипции',
                    'Имя латинскими буквами',
                    (val) => val == null || val.length === 0 || /^[A-Za-z-]*$/.test(val) ? '' :
                        'Латинскими буквами, пожалуйста'),
                yearOfBirth: new Control(
                    'Год рождения',
                    '4 цифры',
                    (val) => val == null || val.length === 0 ? 'Без года рождения не получится' :
                        (val < 1901 || val > 2015 ? 'Год рождения не похож на правдивый' : ''),
                    'number'),
                cellPhone: new Control(
                    'Номер сотового телефона',
                    'номер, который будет использоваться на бревете в виде 9052221133',
                    (val) => val == null || val.length === 0 ? 'Без телефонa в современном мире никуда' :
                        (/\+?\d{10,11}/.test(val) ? '' : 'Извините, но принимаем только цифры в полном количестве'),
                    'tel'),
                relativesPhone: new Control(
                    'Номер телефона родственников',
                    'на случай ЧП, с кодом города, в виде 4712112244',
                    (val) => val == null || val.length === 0 || /\+?\d{10,11}/.test(val) ? ''
                        : 'Извините, но принимаем только цифры c кодом города',
                    'tel'),
                city: new RadioControl(
                    'Ваш город (населённый пункт)',
                    'ваш город',
                    Cities,
                    (val) => val == null || val.length === 0 ? 'Укажите, пожалуйста, город' : ''),
                club: new RadioControl(
                    'Клуб участника',
                    'название вашего клуба',
                    Clubs,
                    (val) => val == null || val.length === 0 ?
                        'Укажите, пожалуйста, клуб. Или выберите "Индивидуально"' : ''),
                bikeType: new DropDownControl(
                    'Тип велосипеда',
                    BikeTypes,
                    (val) => ''),
            }
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this.loader) this.loader.unsubscribe();
    }

    componentDidMount() {
        this._isMounted = true;
        document.title = 'Регистрация на бревет';
        if (this.props.match.params.id) {
            this.loader = BrevetSvc.GetBrevet(parseInt(this.props.match.params.id, undefined), true).subscribe(
                brevet => {
                    this.setState({ brevet });
                    document.title = `Регистрация на бревет ${brevet.name}` +
                        `${' ' + new Date(brevet.date).toLocaleDateString('ru')} `;
                },
                error => this.setState({ isLoading: false, error }),
                () => this.setState({ isLoading: false })
            );
        }
        // load stored previous data
        const regs = BrevetSvc.GetRegistrationData();
        if (regs) {
            const controls = this.state.controls;
            if (regs.bikeType) controls.bikeType.value = regs.bikeType;
            if (regs.cellphone) controls.cellPhone.value = regs.cellphone;
            if (regs.city) {
                controls.city.value = regs.city;
                const opt = controls.city.options.find((value) => regs !== undefined && value === regs.city);
                if (opt === undefined) controls.city.isOtherSelected = true;
            }
            if (regs.club) {
                controls.club.value = regs.club;
                const opt = controls.club.options.find((value) => regs !== undefined && value === regs.club);
                if (opt === undefined) controls.club.isOtherSelected = true;
            }
            if (regs.firstname) controls.firstName.value = regs.firstname;
            if (regs.firstnameLat) controls.firstNameLat.value = regs.firstnameLat;
            if (regs.lastname) controls.lastName.value = regs.lastname;
            if (regs.lastnameLat) controls.lastNameLat.value = regs.lastnameLat;
            if (regs.nick) controls.nick.value = regs.nick;
            if (regs.relativesPhone) controls.relativesPhone.value = regs.relativesPhone;
            if (regs.yearOfBirth) controls.yearOfBirth.value = regs.yearOfBirth;
            this.setState({ controls });
        }
    }

    isValid = (setTouched = false) => {
        if (!this._isMounted) return;
        let valid = true;
        let key: keyof Controls;
        for (key in this.state.controls) {
            if (key in this.state.controls) {
                const ctrl = this.state.controls[key];
                if (ctrl) {
                    ctrl.error = ctrl.isValid(ctrl.value);
                    valid = valid && ctrl.error === '';
                    if (setTouched) { ctrl.touched = true; }
                }
            }
        }
        valid = valid && this.state.agreeWithBrevet.value === true;
        if (setTouched) {
            const agreeWithBrevet = this.state.agreeWithBrevet;
            agreeWithBrevet.touched = true;
            this.setState({ agreeWithBrevet });
        }
        return valid;
    }

    onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (!this._isMounted) return;
        const target = event.target as HTMLInputElement;
        const ctrl = this.state.controls[target.name];
        if (ctrl) {
            ctrl.touched = true;
            this.setState({ controls: this.state.controls });
        }
    }

    onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!this._isMounted) return;
        const target = event.target;
        const name = target.name;
        const ctrl = this.state.controls[name];
        if (ctrl) {
            ctrl.touched = true;
            ctrl.value = target.type === 'checkbox' ? target.checked : target.value;
            ctrl.error = ctrl.isValid(ctrl.value);
            if (ctrl instanceof RadioControl && target.type === 'radio') {
                const radioCtrl = ctrl as RadioControl;
                radioCtrl.isOtherSelected = ctrl.value === '';
            }
            this.setState({ controls: this.state.controls });
        }
    }

    handleSubmit = (event: React.FormEvent<HTMLElement>) => {
        if (!this._isMounted) return;
        event.preventDefault();
        event.stopPropagation();
        if (this.isValid(true)) {
            this.setState({ isSaving: true, errorSend: undefined });
            if (!this.state.brevet) { throw Error('Brevet not set'); }
            BrevetSvc.Register(
                this.state.brevet.id,
                {
                    nick: this.state.controls.nick.value,
                    lastname: this.state.controls.lastName.value,
                    lastnameLat: this.state.controls.lastNameLat.value,
                    firstname: this.state.controls.firstName.value,
                    firstnameLat: this.state.controls.firstNameLat.value,
                    yearOfBirth: this.state.controls.yearOfBirth.value,
                    cellphone: this.state.controls.cellPhone.value,
                    relativesPhone: this.state.controls.relativesPhone.value,
                    city: this.state.controls.city.value,
                    orderMedal: this.state.orderMedal,
                    bikeType: this.state.controls.bikeType.value,
                    club: this.state.controls.club.value
                }
            ).then(
                (regId: string) => {
                    if (!this._isMounted) return;
                    this.setState({ isSaving: false, registredNumber: regId });
                },
                (error) => {
                    if (!this._isMounted) return;
                    this.setState({ isSaving: false, errorSend: error });
                });
        } else {
            // invalid

        }
        return false;
    }

    render() {
        if (this.state.isLoading) {
            return <LoadingIndicator />;
        }

        if (this.state.error || !this.state.brevet) {
            return (
                <Container><Row><Col style={{ paddingTop: '20px' }}>
                    <Alert color="danger">
                        <span>
                            Ошибка получения данных бревета: {' '}
                            {this.state.error ? (this.state.error.message || JSON.stringify(this.state.error)) : ''}
                        </span>
                    </Alert>
                </Col></Row></Container>
            );
        }

        if (this.state.registredNumber) {
            return (
                <Container><Row><Col style={{ paddingTop: '20px' }}>
                    <h1>Регистрация завершена</h1>
                    <p>Поздравляем вас с регистрацией на бревет {this.state.brevet.name}
                        {' ' + new Date(this.state.brevet.date).toLocaleDateString('ru')}</p>
                    <Alert color="success">Ваш стартовый номер: {this.state.registredNumber} <br />
                        Номер телефона:  {this.state.controls.cellPhone.value}</Alert>
                    <p>До встречи на старте!</p>
                    <Link className="btn btn-primary" to={'/' + this.props.match.params.id}>Назад</Link>
                </Col></Row></Container>
            );
        }

        this.isValid();

        const onOrderMedalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (!this._isMounted) return;
            const orderMedal = event.target.checked;
            this.setState({ orderMedal });
        };

        const onAgreeWithBrevetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (!this._isMounted) return;
            const val = event.target.checked;
            this.setState({ agreeWithBrevet: { touched: true, value: val } });
        };

        return (
            <Form onSubmit={this.handleSubmit} >
                <Container className="my-3"><Row><Col>
                    <h1>Регистрация на бревет {this.state.brevet.name} {' '}
                        <small>{new Date(this.state.brevet.date).toLocaleDateString('ru')}</small>
                    </h1>
                    <p>{process.env.REACT_APP_REGISTRATIONNOTES || `Регистрация означает, что вы ознакомились с
Правилами проведения бревета и согласны им следовать. В случае отказа от участия после регистрации необходимо
сообщить организаторам до 17.00 дня, предшествующего бревету. В случае отказа от участия без предупреждения
организаторов и без уважительной причины участник не будет допущен к участию в будущих бреветах без оплаты стоимости
израсходованной на него маршрутной карточки (10 руб.). Если организаторы официально не объявляли о переносе/отмене
бревета, то плохая погода уважительной причиной не является!`}</p>
                </Col></Row>
                    {
                        Object.getOwnPropertyNames(this.state.controls).map((field) => {
                            const control = this.state.controls[field];
                            return (control instanceof RadioControl) ?
                                this.renderRadioField(control as RadioControl, field) :
                                control instanceof DropDownControl ?
                                    this.renderDropDownField(control as DropDownControl, field) :
                                    this.renderTextField(control!!, field);
                        })
                    }
                    <Row><Col sm={{ size: 10, offset: 2 }}>
                        <FormGroup check={true}>
                            <Input
                                type="checkbox"
                                name="orderMedal"
                                id="orderMedal"
                                checked={this.state.orderMedal}
                                onChange={onOrderMedalChange}
                            />{' '}
                            <Label check={true}> Заказ медали </Label>
                            <div className="form-text">{process.env.REACT_APP_REGISTRATIONMEDALNOTE ||
                                'Справочно, фактический заказ будет перед стартом, одновременно с оплатой'}</div>
                        </FormGroup>
                        <h4 style={{ paddingTop: '20px' }}>Согласие с условиями участия в бревете</h4>
                        <p>{process.env.REACT_APP_REGISTRATIONAGREENOTE || `Участник признает, что организация бревета
                            заключается в регистрации маршрута, сборе сведений об участниках (не являющихся персональными данными), передаче
                            результатов головной организации (ОРВМ), при необходимости - организация КП и зоны финиша, а также предоставлении
                            памятных медалей. Передвижение участника по дорогам общего пользования является индивидуальной поездкой по заранее
                            определенному маршруту, и организуется им самим. Участник самостоятельно несет ответственность за соблюдение Правил
                            дорожного движения, а также иных законодательных актов России и региона проведения бревета.`}</p>
                        <FormGroup check={true}>
                            <Input
                                type="checkbox"
                                name="agreeWithBrevet"
                                id="agreeWithBrevet"
                                checked={this.state.agreeWithBrevet.value}
                                onChange={onAgreeWithBrevetChange}
                                valid={!this.state.agreeWithBrevet.touched ? undefined :
                                    this.state.agreeWithBrevet.value}
                                invalid={!this.state.agreeWithBrevet.touched ? undefined :
                                    !this.state.agreeWithBrevet.value}
                            />
                            <Label check={true} >Согласен с условиями</Label>
                            {this.state.agreeWithBrevet.touched && !this.state.agreeWithBrevet.value &&
                                <FormFeedback>Нет согласия - нет регистрации</FormFeedback>
                            }
                        </FormGroup>
                    </Col></Row>
                    {this.state.errorSend &&
                        <Row><Col style={{ paddingTop: '20px' }}>
                            <Alert color="danger">
                                <span>
                                    Ошибка регистрации: {' ' + this.state.errorSend}
                                </span>
                            </Alert>
                        </Col></Row>
                    }
                    <Row style={{ paddingTop: '20px' }}><Col sm={{ size: 10, offset: 2 }}>
                        <Button
                            id="submit"
                            color="primary"
                            type='submit'
                            disabled={this.state.isSaving}
                        >
                            {this.state.isSaving ? 'Регистрация...' : 'Зарегистрироваться'}
                        </Button>
                    </Col></Row></Container >
            </Form>
        );
    }

    renderDropDownField(control: DropDownControl, name: string) {
        return (
            <FormGroup row={true} key={name}>
                <Label for={name} sm={2}>{control.label}</Label>
                <Col sm={10}>
                    <Input
                        type="select"
                        name={name}
                        id={name}
                        value={control.value}
                        onChange={this.onChange}
                        onBlur={this.onBlur}
                    >
                        {control.options.map(opt => <option key={opt}>{opt}</option>)}
                    </Input>
                </Col>
            </FormGroup>
        );
    }

    renderRadioField(control: RadioControl, name: string) {
        return (
            <FormGroup
                tag="fieldset"
                key={name}
            >
                <Row> {/* cant  put row on Fields set because of chrome bug */}
                    <Label for={name} sm={2}>{control.label}</Label>
                    <Col sm={10}>
                        {control.options.map((opt) => (
                            <FormGroup check={true} key={opt}>
                                <Label check={true}>
                                    <Input
                                        type="radio"
                                        name={name}
                                        value={opt}
                                        checked={control.value === opt}
                                        onChange={this.onChange}
                                        valid={!control.touched ? undefined : control.error === ''}
                                        invalid={!control.touched ? undefined : control.error !== ''}
                                    />{' '} {opt === '' ? 'Другое' : opt}
                                </Label>
                            </FormGroup>
                        ))}
                        <FormGroup check={true}>
                            <Label check={true}>
                                <Input
                                    type="radio"
                                    name={name}
                                    value=""
                                    checked={control.isOtherSelected}
                                    onChange={this.onChange}
                                />  Другое
                            </Label>
                        </FormGroup>
                        <Input
                            hidden={!control.isOtherSelected}
                            name={name}
                            id={name}
                            placeholder={control.placeholder}
                            value={control.value}
                            onChange={this.onChange}
                            onBlur={this.onBlur}
                            valid={!control.touched ? undefined : control.error === ''}
                            invalid={!control.touched ? undefined : control.error !== ''}
                        />
                        {control.touched && control.error !== '' &&
                            <FormFeedback>{control.error}</FormFeedback>}
                    </Col>
                </Row>
            </FormGroup>
        );
    }

    renderTextField(control: Control, name: string) {
        return (
            <FormGroup
                id={name}
                row={true}
                key={name}
            >
                <Label for={name} sm={2}>{control.label}</Label>
                <Col sm={10}>
                    <Input
                        name={name}
                        id={name}
                        placeholder={control.placeholder}
                        value={control.value}
                        onChange={this.onChange}
                        onBlur={this.onBlur}
                        type={control.type}
                        valid={!control.touched ? undefined : control.error === ''}
                        invalid={!control.touched ? undefined : control.error !== ''}
                    />
                    {control.error !== '' &&
                        <FormFeedback>{control.error}</FormFeedback>}
                </Col>
            </FormGroup>
        );
    }
}

export default Register;